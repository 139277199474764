import { request } from './request'

/*根据senuserid查询*/
export function getSenUserId(id) {
  return request({
    url: '/new/findsenuserId',
    params: {
      id
    }
  })
}
/*根据senuserid查询*/
export function getRecUserId(id) {
  return request({
    url: '/new/findrecuserId',
    params: {
      id
    }
  })
}

/*根据news id查询*/
export function getNewsId(id) {
  return request({
    url: '/user/message',
    params: {
      id
    }
  })
}

/*获取用户的系统消息记录数*/
export function getMessageCount(type) {
  return request({
    url: '/user/message_count',
    params: {
      type
    }
  })
}

/*根据所有用户消息*/
export function getMessageList(status) {
  return request({
    url: '/user/message',
    params: {
      status
    }
  })
}

/*用户的系统消息设置已读*/
export function clearMessage() {
  return request({
    url: '/user/clear_message'
  })
}
/*获取收到的评论*/
export function queryMyCommentList(p) {
  return request({
    url: '/user/my_comment_list',
    params: {
      p
    }
  })
}

/*获取收到的赞和收藏*/
export function queryReceivedlikesList(p) {
  return request({
    url: '/user/receivedlikes',
    params: {
      p
    }
  })
}
/*根据news id查询*/
export function getNews() {
  return request({
    url: '/new/findnews'
  })
}

/*添加消息*/
export function addNews(senuser_id, news, recuser_id) {
  return request({
    url: '/new/addnews',
    params: {
      senuser_id,
      news,
      recuser_id
    }
  })
}
